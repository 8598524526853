import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Event } from "../components/Tracking";

import LOGO from '../imgs/logo/21-20035-SPLK-Open-Telemetry-Game-Pipe-Storm-Logo.svg';

import './welcomePageMobile.css';
// import styles from './welcomePageMobile.module.scss';

let gameSetupJSON = require('../gameSetup.json');



export default function WelcomePageMobile(props) {
    return (
        <>
            <div className='welcome-page-m'>



                <div className="d-flex flex-column full-height w-100">
                    <div className="d-flex welcome-logo-m justify-content-center">
                        <img id="welcome-logo-img-m" src={LOGO} alt="logo" />
                    </div>

                    <div className="right-m">
                        {/* <div className="welcome-block-m"> */}
                        <div className="welcome-text-m d-flex align-items-center justify-content-center">

                            <div>{ReactHtmlParser(gameSetupJSON['welcome-m-1'])}</div>

                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="button-gen" onClick={() => { props.startTheGame(); Event("Game Transition", "User clicked the PLAY button"); }} >
                                PLAY
                            </div>
                        </div>
                       {/* <div className="restriction-text-m d-flex align-items-center justify-content-center">

                            <div>{ReactHtmlParser(gameSetupJSON['welcome-m-2'])}</div>

                        </div> */}

                        {/* </div> */}
                    </div>


                </div>
            </div>
        </>

    )
}
