import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import WelcomePage from './pages/welcomePage';
import WelcomePageMobile from './pages/welcomePageMobile';

import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';


import Game from './pages/game';
import GameMobile from './pages/gameMobile';

import { FinalPage } from './pages/FinalPage';
import { FinalPageMobile } from './pages/FinalPageMobile';

import { PageView, initGA, Event } from './components/Tracking';
import { MobileScreen } from './pages/mobileScreen';
import WillStartSoon from './pages/WillStartSoon';
import GameIsOver from './pages/GameIsOver';

import CookieConsent from "react-cookie-consent";
import axios from 'axios';

import './App.css';




export default class App extends Component {

  state = {

    welcomePage: false,
    gamePage: false,
    finalPage: false,
    willStartSoon: false,
    gameIsOver: false,
    points: "",
    username: "",
    sessionId: "",
    userPlace: "",
    redirect: '',

  }

  componentDidMount = () => {
    // initialize Google Analitycs 
    initGA(process.env.REACT_APP_GOOGLE_MEASURMENT_ID);
    PageView();

    // check game period if not a demo
    if (!this.props.demo) {
     this.setState({
        welcomePage: true
      })
    } else {
      this.setState({
        welcomePage: true
      })
    }
  }

  checkGameTime = () => {
    try {
      let now = new Date();
      let startDay = new Date(process.env.REACT_APP_START_DAY);
      let lastDay = new Date(process.env.REACT_APP_FINAL_DAY);
      if (now < startDay) {
        this.setState({
          willStartSoon: true
        })
      } else if (now > startDay && now < lastDay) {
        this.setState({
          welcomePage: true
        })
      } else {
        // prize game is over
        this.setState({
          gameIsOver: true
        })
      }

    } catch (err) {
      console.log(err.message)
    }
  }

  startTheGame = () => {
    this.setState({
      welcomePage: false,
      gamePage: true,
      finalPage: false,
    })
  }

  finishTheGame = (points) => {
    Event("Game Transition", "User clicked the FINISH button");
    this.saveScoreToDb(points);
    this.setState({
      gamePage: false,
      finalPage: true,
      points: points
    })
  }

  resetUserData = () => {
    this.setState({
      username: '',
      sessionId: '',
      points: '',
      userPlace: ''
    })
  }

  saveScoreToDb = (points) => {
    // console.log(process.env.REACT_APP_API_URL);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_API_KEY
    };

    axios
      .post(`api/v1/scores/add?score=${points}`, {}, { headers: headers })
      .then((res) => {
        this.setState({
          username: res.data.username,
          sessionId: res.data.session_id,
          stress: res.data.score,
          userPlace: res.data.userPlace
        })
      })
  };

  clamePrize = () => {
    Event("Marketing", "User opened PRIZE FORM");

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_API_KEY
    };
    // console.log(sessionId);
    axios
      .put(`api/v1/prizes/?sessionId=${this.state.sessionId}`, {}, { headers: headers })
      .then((res) => {
        // console.log(res.data);
      })

  }

  render() {

    let background = ''
    if (this.state.gamePage) {
      background = "two-background"
    } else {
      background = "one-background"
    }
    return (
      <>
        <div className="desktop">

          <div className={`fullscreen ${background} `}>

            <CookieConsent
              location="bottom"
              buttonText="I Understand"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#222D42" }}
              buttonStyle={{ color: "white  ", fontSize: "13px", background: "#ED0080" }}
              expires={150}
              enableDeclineButton
              onDecline={() => {
                // document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";;
                this.setState({ redirect: true });
              }}
            >
              This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>

            {this.state.redirect ? (<Redirect push to="/splunk" />) : null}

            {
              this.state.welcomePage &&
              <WelcomePage
                startTheGame={this.startTheGame} />
            }
            {(this.state.gamePage || this.state.finalPage) && <Navbar gamePage={this.state.gamePage} />}
            {
              this.state.gamePage &&
              <Game
                finishTheGame={this.finishTheGame}
                saveScoreToDb={this.saveScoreToDb} />
            }
            {
              this.state.finalPage &&
              <FinalPage
                points={this.state.points}
                username={this.state.username}
                sessionId={this.state.sessionId}
                userPlace={this.state.userPlace}
                clamePrize={this.clamePrize}
                startTheGame={this.startTheGame}
                resetUserData={this.resetUserData}
              />
            }
            {this.state.willStartSoon && <WillStartSoon />}

            {this.state.gameIsOver && <GameIsOver />}

          </div >
          {/* </div> */}
        </div>

        <div className="mobile">
          {/* <MobileScreen /> */}

          <CookieConsent
            location="bottom"
            buttonText="I Understand"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#222D42" }}
            buttonStyle={{ color: "white  ", fontSize: "13px", background: "#ED0080" }}
            expires={150}
            enableDeclineButton
            onDecline={() => {
              // document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";;
              this.setState({ redirect: true });
            }}
          >
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>

          {this.state.redirect ? (<Redirect push to="/splunk" />) : null}

          {
            this.state.welcomePage &&
            <WelcomePageMobile
              startTheGame={this.startTheGame} />
          }

          {(this.state.gamePage || this.state.finalPage)
            && <NavbarMobile gamePage={this.state.gamePage} />}


          {
            this.state.gamePage &&
            <GameMobile
              finishTheGame={this.finishTheGame}
              saveScoreToDb={this.saveScoreToDb} />
          }

          {
            this.state.finalPage &&
            <FinalPageMobile
              points={this.state.points}
              username={this.state.username}
              sessionId={this.state.sessionId}
              userPlace={this.state.userPlace}
              clamePrize={this.clamePrize}
              startTheGame={this.startTheGame}
              resetUserData={this.resetUserData}
            />
          }

          {
            this.state.gameIsOver &&
            <GameIsOver />
          }


        </div>
      </>
    )
  }
}
