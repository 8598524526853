import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ScoreTable } from '../components/ScoreTable';
import { Event } from "../components/Tracking";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";

import FBIcon from '../imgs/social-media/Facebook-Button.png';
import TwittIcon from '../imgs/social-media/Twitter-Button.png';
import LinkIcon from '../imgs/social-media/LinkedIn-Button.png';
import LOGO from '../imgs/logo/21-20035-SPLK-Open-Telemetry-Game-Pipe-Storm-Logo.svg'

import './finalMobile.css';

let gameText = require('../gameSetup.json');

export default function SocialMediaButtons(props) {
    return (
        <>
            <span onClick={() => { Event("Marketing", "User shared via Facebook"); }}>
                <FacebookShareButton
                    url={"https://pipestorm.splunk.com"}
                    quote={gameText['social-media']}
                    hashtag="#splunk"
                >
                    <img className="social-icon zoom" src={FBIcon} alt="" />

                </FacebookShareButton>
            </span>
            <span onClick={() => { Event("Marketing", "User shared via Linkedin"); }}>

                <LinkedinShareButton
                    url={"https://pipestorm.splunk.com/"}
                    quote={gameText['social-media']}
                    hashtag="#splunk"
                    title="Play with me!"
                    summary={gameText['social-media']}
                    source={props.source}
                >
                    <img className="social-icon zoom" src={LinkIcon} alt="" />

                </LinkedinShareButton>
            </span>

            <span onClick={() => { Event("Marketing", "User shared via Twitter"); }}>

                <TwitterShareButton
                    url={gameText['social-media-twitter']}
                    quote={gameText['social-media']}
                    hashtag="#splunk"
                >
                    <img className="social-icon zoom" src={TwittIcon} alt="" />

                </TwitterShareButton>
            </span>

        </>
    );
}

export function FinalPageMobile(props) {

    let prizeLink = `https://response.splunk.com/OpenTelemetry-Interactive-Game?sessionid=${props.sessionId}&gamehandle=${props.username}#register`

    return (

        <div className="d-flex flex-column final-container-m">

            <div className="final-logo-block-m">
                <img src={LOGO} alt="" />
            </div>

            <div className="d-flex justify-content-center">
                <div className="congrats-img-m"></div>
            </div>

            <div className="d-flex justify-content-center">
                <div className="final-text-m">
                    <p>You earned <span className="highlight">{props.points} points</span> and got <span className="highlight">{props.userPlace}</span> place as <span className="highlight">{props.username}</span>!</p>
                    {ReactHtmlParser(gameText.final1)}
                </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
                <a id="pr" className="button-prize-m" onClick={() => {
                    props.clamePrize()
                }}
                    href={prizeLink}
                    target="_blank"
                    rel="noreferrer"><span className="zoom">Submit Entry</span>
                </a>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-2">
                <div className="button-replay-m" onClick={() => { props.startTheGame(); props.resetUserData(); Event("Game Transition", "User clicked the REPLAY-final button"); }} >
                    <span className="zoom">Replay</span>
                </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
                <p>Share your success with everybody</p>
            </div>

            <div className="d-flex justify-content-center social-media-bttns">
                <SocialMediaButtons source="https://pipestorm.splunk.com/" />
            </div>
            {/* <div className="congrats">
                <div className="d-flex justify-content-center">
                    <div className="congrats-img"></div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="final-text">
                        <p>You earned <span className="highlight">{props.points} points</span> and got <span className="highlight">{props.userPlace}</span> place as <span className="highlight">{props.username}</span>!</p>
                        {ReactHtmlParser(gameText.final1)}
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <a id="pr" className="button-prize" onClick={() => {
                        props.clamePrize()
                    }}
                        href={prizeLink}
                        target="_blank"
                        rel="noreferrer"><span className="zoom">Submit Entry</span>
                    </a>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                    <div className="button-replay" onClick={() => { props.startTheGame(); props.resetUserData(); Event("Game Transition", "User clicked the REPLAY-final button"); }} >
                        <span className="zoom">Replay</span>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <p>Share your success with everybody</p>
                </div>

                <div className="d-flex justify-content-center social-media-bttns">
                    <SocialMediaButtons source="https://pipestorm.splunk.com/" />
                </div>



            </div> */}

            {/* <div className="final-right">
                <div className="d-flex justify-content-center">
                    <div className="score-background">
                        {props.username && <>
                            <div className="mt-2 ">
                                <div className="text-center">
                                    <p id="lead-title">Leaderboard</p>

                                </div>
                                {props.username && <div id="score-block" className="d-flex justify-content-center">
                                    <div id="score-table" >
                                        <div className="score-frame">
                                            <ScoreTable />

                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </>}
                    </div>

                </div>
            </div> */}
        </div>

    )
}
