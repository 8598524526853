import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Event } from "../components/Tracking";

import LOGO from '../imgs/logo/21-20035-SPLK-Open-Telemetry-Game-Pipe-Storm-Logo.svg';

import './welcomePage.css';

let gameSetupJSON = require('../gameSetup.json');



export default function WelcomePage(props) {
    return (
        <>
            <div className="welcome-page">
                {/* <div className="test"></div> */}
                <div className="d-flex full-height w-100">

                    <div className="d-flex welcome-logo justify-content-center">
                        <img id="welcome-logo-img" src={LOGO} alt="logo" />
                    </div>
                    <div className="right full-height ">
                        <div className="welcome-block full-height">
                            <div className="welcome-text d-flex align-items-center justify-content-center">

                                <div>{ReactHtmlParser(gameSetupJSON['welcome1'])}</div>

                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="button-gen" onClick={() => { props.startTheGame(); Event("Game Transition", "User clicked the PLAY button"); }} >
                                    PLAY
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>

    )
}
