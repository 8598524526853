import React, { useState, useEffect } from 'react';
import { ScoreTable } from './ScoreTable';

import Modal from "react-modal";

import './navbar.css';

import SPLUNKLOGO from '../imgs/logo/splunk-logo-white.png';
import CLOSE from '../imgs/x-button-gray.svg';
import raw from '../rules.txt';

Modal.setAppElement("#root");


export const RulesText = (props) => {
    const [text, setText] = useState("");

    useEffect(() => {
        fetch(raw)
            .then(r => r.text())
            .then(setText)
    }, [])

    let textFormated = text.split('\n').map((item, i) => { return <p key={i}>{item}</p>; })

    return textFormated

}


export default function Navbar(props) {

    const [scoreIsOpen, setScoreIsOpen] = useState(false);
    const [rulesAreOpen, setRulesAreOpen] = useState(false);

    function toggleScoreModal() {
        setScoreIsOpen(!scoreIsOpen);
    }

    function toggleRulesModal() {
        setRulesAreOpen(!rulesAreOpen);
    }
    
     /* Rules Button removed since it is not actively with a campaign, please place below back underneath Scoreboard div
                    <div className="nav-item align-self-center  zoom " onClick={toggleRulesModal}>
                        Rules
                    </div> */

    return (
        <>
            <div className="navbar-custom">
                <div className="d-flex justify-content-end ">
                    {props.gamePage && <div className="nav-item align-self-center zoom " onClick={toggleScoreModal}>
                        Scoreboard
                    </div>}

                    <div className="col-2">
                        <div>
                            <img id="logo-splunk" src={SPLUNKLOGO} alt="" />

                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={scoreIsOpen}
                onRequestClose={toggleScoreModal}
                contentLabel="My dialog"
                className="mymodal-score"
                overlayClassName="myoverlay"
                closeTimeoutMS={0}
            >
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-1">
                            <img className="close-btn zoom" onClick={toggleScoreModal} src={CLOSE} alt="close" />
                        </div>
                    </div>
                    <div className="">
                        <h3>25 Best Data Plumbers</h3>
                        <div className="score-table-modal">
                            {/* <div className="scroll"> */}
                            <ScoreTable />
                            {/* </div> */}
                        </div>
                    </div>

                </div>
            </Modal>

            <Modal
                isOpen={rulesAreOpen}
                onRequestClose={toggleRulesModal}
                contentLabel="My dialog"
                className="mymodal-rules"
                overlayClassName="myoverlay"
                closeTimeoutMS={0}
            >
                <div className="container p-0">
                    <div className="row justify-content-end">
                        <div className="col-1">
                            <img className="close-btn zoom" onClick={toggleRulesModal} src={CLOSE} alt="close" />

                        </div>

                    </div>
                    <RulesText />
                </div>
            </Modal>

        </>
    )
}
