import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import HttpsRedirect from 'react-https-redirect';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Rules from "./pages/rules";

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <BrowserRouter>
        <Switch>

          <Route exact path='/rules' component={Rules} />

          <Route path='/splunk' component={() => {
            window.location.href = 'https://splunk.com';
            return null;
          }} />

          <Route path='/demo' render={(props) => <App demo={true} {...props} />} />

          <Route path='/' component={App} />

        </Switch>
      </BrowserRouter>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
