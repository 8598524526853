import React from 'react';
import Modal from "react-modal";
import { Event } from "../components/Tracking";
import './modal.css';

export function AlertModalReset(props) {

    let { alertResetIsOpen, toggleAlertResetModal } = props;

    return (
        <>
            <Modal
                isOpen={alertResetIsOpen}
                onRequestClose={toggleAlertResetModal}
                contentLabel="My dialog"
                className="mymodal-alert"
                overlayClassName="myoverlay"
                closeTimeoutMS={0}
                shouldCloseOnOverlayClick={true}
            >
                <div className="container overflow-hidden">
                    <div className="modal-text-block">
                        <div className="result-text">
                            <p>You cannot edit this answer anymore. Do you want to restart the game?</p>
                        </div>

                        <div className="d-flex  justify-content-end">
                            <div onClick={() => { toggleAlertResetModal(); props.resetAnswers(); Event("Game Transition", "User clicked the RESTART button") }} className="button-modal" >RESTART </div>
                            <div onClick={() => { toggleAlertResetModal(); Event("Game Transition", "User clicked the CONTINUE button") }} className="button-modal">CONTINUE</div>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export function AlertModalOk(props) {

    let { alertOKIsOpen, toggleAlertOKModal } = props;

    return (
        <>
            <Modal
                isOpen={alertOKIsOpen}
                onRequestClose={toggleAlertOKModal}
                contentLabel="My dialog"
                className="mymodal-alert"
                overlayClassName="myoverlay"
                closeTimeoutMS={0}
                shouldCloseOnOverlayClick={true}


            >
                <div className="container overflow-hidden">
                    <div className="modal-text-block">
                        <div className="result-text">
                            <p>You have to answer the previous question first.</p>
                        </div>

                        <div className="d-flex  justify-content-end">
                            <div onClick={() => { toggleAlertOKModal(); Event("Game Transition", "User clicked the OK button") }} className="button-modal">OK</div>
                        </div>

                    </div>

                </div>
            </Modal>
        </>
    )
}

