import React from 'react';

import Q1 from '../imgs/Q1-Q7_PNG/Q1-Inactive.png';
import Q2 from '../imgs/Q1-Q7_PNG/Q2-Inactive.png';
import Q3 from '../imgs/Q1-Q7_PNG/Q3-Inactive.png';
import Q4 from '../imgs/Q1-Q7_PNG/Q4-Inactive.png';
import Q5 from '../imgs/Q1-Q7_PNG/Q5-Inactive.png';
import Q6 from '../imgs/Q1-Q7_PNG/Q6-Inactive.png';
import Q7 from '../imgs/Q1-Q7_PNG/Q7-Inactive.png';


import Q1a from '../imgs/Q1-Q7_PNG/Q1-Active.png';
import Q2a from '../imgs/Q1-Q7_PNG/Q2-Active.png';
import Q3a from '../imgs/Q1-Q7_PNG/Q3-Active.png';
import Q4a from '../imgs/Q1-Q7_PNG/Q4-Active.png';
import Q5a from '../imgs/Q1-Q7_PNG/Q5-Active.png';
import Q6a from '../imgs/Q1-Q7_PNG/Q6-Active.png';
import Q7a from '../imgs/Q1-Q7_PNG/Q7-Active.png';
import Placeholder from '../imgs/other/placeholder.png';


import uuid from 'react-uuid';

const TitleBuilder = (props) => {

    return (
        <>
            <tr>
                <td className="q-title"><img src={props.button} alt="" /></td>

            </tr>
            <tr>
                <td className="q-title">
                    <img src={Placeholder} alt="" />
                </td>
            </tr>
        </>
    )
}

export default function QuestionTitles(props) {

    var buttonsList = [Q1, Q2, Q3, Q4, Q5, Q6, Q7];
    var buttonsListActive = [Q1a, Q2a, Q3a, Q4a, Q5a, Q6a, Q7a];
    var indexList = [0, 1, 2, 3, 4, 5, 6];

    let { activeQuestion } = props;

    return (
        <table id="questions">
            <thead>

            </thead>
            <tbody>
                <tr>
                    <td className="q-title">
                        <img src={Placeholder} alt="" />
                    </td>
                </tr>
                {/* <tr>
                    <td className="q-title"></td>

                </tr> */}
                {indexList.map(index => {
                    if (index === activeQuestion) {
                        return <TitleBuilder key={uuid()} button={buttonsListActive[index]} />
                    } else {
                        return <TitleBuilder key={uuid()} button={buttonsList[index]} />

                    }
                })}
            </tbody>

        </table>
    )
}
