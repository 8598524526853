import React from 'react';
import Modal from "react-modal";
import ReactHtmlParser from 'react-html-parser';
import { Event } from "../components/Tracking";

import './modal.css';

export default function ResultModal(props) {

    let { resultIsOpen, toggleResultModal, resultsMessage, points } = props;

    let noPrizeMessage = "I guess the problem really wasn’t DNS. Let’s try again!";

    let showPrizeButton = resultsMessage !== noPrizeMessage;


    return (
        <>
            <Modal
                isOpen={resultIsOpen}
                onRequestClose={toggleResultModal}
                contentLabel="My dialog"
                className="mymodal-result"
                overlayClassName="myoverlay"
                closeTimeoutMS={0}
                shouldCloseOnOverlayClick={false}


            >
                <div className="container overflow-hidden">
                    <div className="modal-text-block">

                        <div className="result-text">
                            <p>{ReactHtmlParser(resultsMessage)}</p>
                        </div>

                        <div className="d-flex justify-content-end">
                            <div onClick={() => {
                                props.resetAnswers(); Event("Game Transition", "User clicked the RESTART button");
                            }} className="button-modal">RESTART</div>
                            {showPrizeButton && <div onClick={() => { props.finishTheGame(points) }} className="button-modal">FINISH</div>}
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )

}
