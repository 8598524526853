import React, { Component } from 'react'
import axios from 'axios';



export class ScoreTable extends Component {

    state = {
        scoreList: [],
    }

    componentDidMount = () => {
        let headers = { 'authorization': process.env.REACT_APP_API_KEY }
        axios.get(`api/v1/scores/?count=25`, { headers }).then(res => this.setState({ scoreList: res.data.data }))
    }


    render() {
        return (
            <>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th className="text-center pink" scope="col text-center">#</th>
                            <th className="pink" scope="col">Username</th>
                            <th className="text-center pink" scope="col text-center">Points </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.scoreList && this.state.scoreList.map((element, index) => {
                            return (
                                <tr key={index.toString()}>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{element.username}</td>
                                    <td className="text-center">{element.score}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </>
        )
    }
}


