import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import QuestionTitlesMobile from '../components/QuestionTitlesMobile';
import ResultModal from '../components/ResultModal';
import { AlertModalOk, AlertModalReset } from '../components/AlertModal';
import { Event } from "../components/Tracking";

import uuid from 'react-uuid';

import './gameMobile.css';

// pipes
import ONE from '../imgs/pipes/Pipes-01.svg';
import TWO from '../imgs/pipes/Pipes-02.svg';
import THREE from '../imgs/pipes/Pipes-06.svg';
import FOUR from '../imgs/pipes/Pipes-05.svg';
import FIVE from '../imgs/pipes/Pipes-04.svg';
import SIX from '../imgs/pipes/Pipes-03.svg';

// inactive letters
import A from '../imgs/A-D_PNG/A-Inactive.png';
import B from '../imgs/A-D_PNG/B-Inactive.png';
import C from '../imgs/A-D_PNG/C-Inactive.png';
import D from '../imgs/A-D_PNG/D-Inactive.png';

// active letters
import Aa from '../imgs/A-D_PNG/A-Active.png';
import Ba from '../imgs/A-D_PNG/B-Active.png';
import Ca from '../imgs/A-D_PNG/C-Active.png';
import Da from '../imgs/A-D_PNG/D-Active.png';


import LOGO from '../imgs/logo/OT-Logo.svg';
import CLOUDS from '../imgs/pipes/Starting-Pipe.png';
import PIPES_END_RIGHT from '../imgs/pipes/Ending-Pipe-right.png';
import PIPES_END_LEFT from '../imgs/pipes/Ending-Pipe-left.png';
import Modal from "react-modal";
import Placeholder from '../imgs/other/placeholder.png';


// import Dashboards
import DASHBOARD1 from '../imgs/Dashboard/Dashboard-01.svg';
import DASHBOARD2 from '../imgs/Dashboard/Dashboard-02.svg';
import DASHBOARD3 from '../imgs/Dashboard/Dashboard-03.svg';
import DASHBOARD4 from '../imgs/Dashboard/Dashboard-04.svg';
import DASHBOARD5 from '../imgs/Dashboard/Dashboard-05.svg';
import DASHBOARD6 from '../imgs/Dashboard/Dashboard-06.svg';
import DASHBOARD7 from '../imgs/Dashboard/Dashboard-07.svg';

// import Terminal
import TERMINAL from '../imgs/block/Terminal-Screen.png';

Modal.setAppElement("#root");


let questionsJSON = require('../questions.json');
let gameSetupJSON = require('../gameSetup.json');


const ABC = ["A", "B", "C", "D"];

const ABCImg = [A, B, C, D];
const ABCImgActive = [Aa, Ba, Ca, Da];



const AnswerOption = (props) => {
    let { text } = props;

    return <li><p className="white-f">{ReactHtmlParser(text)}</p></li>

    // return <li><span className="abc">{letter}:</span>{ReactHtmlParser(text)}</li>
}

const QuestionPreview = (props) => {

    let { activeQuestion, schema } = props;

    // get answers from schema
    let rowIndex = (activeQuestion + 1) * 2;
    let answersRow = schema[rowIndex];

    let answersList = [];
    for (let i = 0; i < 4; i++) {
        answersList.push(answersRow[i * 3]['text'])
    }

    let text = questionsJSON[activeQuestion].text;
    return (
        <>
            <div className="question-container-m">
                <div className="question-container-text-m">
                    <h4>{questionsJSON[activeQuestion].name}</h4>
                    <div>{ReactHtmlParser(text)}</div>
                    <div className="answers-container">
                        <ol type="A" id="answers-list">
                            {questionsJSON && answersList.map((answer, index) => {
                                // return <p><span className="abc">{ABC[index]}:</span>{ReactHtmlParser(answer.text)}</p>
                                return <AnswerOption
                                    key={uuid()}
                                    text={answer}
                                />
                            })}
                        </ol>
                    </div>
                </div>


            </div>
        </>
    )
}

const Pipe = (props) => {

    var disct = {
        ONE: ONE,
        TWO: TWO,
        THREE: THREE,
        FOUR: FOUR,
        FIVE: FIVE,
        SIX: SIX
    }

    return (
        <>
            <img className="pipe-img" src={disct[props.pipe]} alt="" />
        </>
    )
}

const Answer = (props) => {

    let { isActive, activeRow, columnIndex } = props;
    // index = index[index.length - 1];
    let letterIndex = columnIndex / 3;
    return (
        <td
            className={isActive ? `aswear-cell selectedAnswer ${ABC[letterIndex]}` : `aswear-cell ${ABC[letterIndex]}`}
        >
            <img onClick={(e) => { props.selectAnswer(e) }}
                id={props.id}
                src={activeRow ? ABCImgActive[letterIndex] : ABCImg[letterIndex]}
                alt="" />
        </td>
    );
}

const Row = (props) => {
    let { row, selectedAnswers, rowIndex, activeQuestion } = props;

    return (
        <tr>
            {row.map((cell, columnIndex) => {
                let cellId = columnIndex + '-' + rowIndex;
                let isActive = false;

                let selected = selectedAnswers.indexOf(cellId);
                if (selected !== -1) {
                    isActive = true
                }

                if (cell.text) {
                    // check if it's an active row
                    let activeRow = '';
                    if (Number(cell.id[0]) === activeQuestion) {
                        activeRow = true
                    } else {
                        activeRow = false
                    }

                    return <Answer
                        key={uuid()}
                        selectAnswer={props.selectAnswer}
                        id={cellId}
                        text={cell.text}
                        isActive={isActive}
                        index={cell.id}
                        activeRow={activeRow}
                        columnIndex={columnIndex} />
                }
                if (cell.pipe) {
                    return (
                        <td key={uuid()} id={cellId}>
                            <Pipe pipe={cell.pipe} />
                        </td>)
                }
                return <td key={uuid()} id={cellId}><img className="pipe-img" src={Placeholder} alt="" /></td>
            })}
        </tr>
    )
}

export default class GameMobile extends Component {

    state = {
        schema: [],
        selectedAnswers: ['6-0'],
        activeQuestion: 0,
        points: 0,
        score: [],
        scoreFinal: '',
        resultsMessage: '',
        resultIsOpen: false,
        alertOKIsOpen: false,
        alertResetIsOpen: false,
        dashboard: DASHBOARD6,
        comment: '$ ...',
        commentTitle: '',
        gameIsOver: false
    }

    componentDidMount = () => {
        this.populateSchema();
    }

    toggleResultModal = () => {
        this.setState({
            resultIsOpen: !this.state.resultIsOpen
        })
    }

    toggleAlertOKModal = () => {
        this.setState({
            alertOKIsOpen: !this.state.alertOKIsOpen
        })
    }

    toggleAlertResetModal = () => {
        this.setState({
            alertResetIsOpen: !this.state.alertResetIsOpen
        })
    }

    updatePoints = (points) => {
        const scoreDict = { "wrong": 1, "bad": 2, "okay": 3, "best": 4 }
        // score needs to be multiplied by the coefficient of the previous answer
        // get the previous answer
        let coefficient = '';
        let scoreList = this.state.score;
        if (scoreList.length > 0) {
            let lastScore = scoreList[scoreList.length - 1];
            coefficient = scoreDict[lastScore];

            let newPoints = this.state.points + Number(points) * coefficient;

            this.setState({
                points: newPoints,
            })
        } else {
            coefficient = 1;
            let newPoints = this.state.points + Number(points) * coefficient;
            this.setState({
                points: newPoints,
            })
        }

    }

    updateScore = (score) => {
        this.setState({
            score: [...this.state.score, score]
        })
    }

    checkResult = () => {
        let score = this.state.score;

        if (score.indexOf('wrong') > -1) {
            this.setState({
                resultsMessage: gameSetupJSON['wrong'],
                scoreFinal: 'wrong'
            })

        } else if (score.indexOf('bad') > -1) {
            this.setState({
                resultsMessage: gameSetupJSON['bad'],
                scoreFinal: 'bad'
            })
        } else if (score.indexOf('okay') > -1) {
            this.setState({
                resultsMessage: gameSetupJSON['okay'],
                scoreFinal: 'okay'
            })
        } else {
            this.setState({
                resultsMessage: gameSetupJSON['best'],
                scoreFinal: 'best'
            })
        }
    }

    shuffle = (arr) => {
        var j, x, index;
        for (index = arr.length - 1; index > 0; index--) {
            j = Math.floor(Math.random() * (index + 1));
            x = arr[index];
            arr[index] = arr[j];
            arr[j] = x;
        }
        return arr;
    }

    populateSchema = () => {
        const schema = [];
        const questionsCount = questionsJSON.length;
        const answersCount = questionsJSON[0].answers.length;
        let cellCount = answersCount + (answersCount - 1) * 2;

        const emptyRow = new Array(cellCount).fill("");

        // first empty row
        schema.push([...emptyRow]);
        schema.push([...emptyRow]);

        for (let i = 0; i < questionsCount; i++) {
            let row = [];
            // shuffle answers
            let answersList = questionsJSON[i].answers;
            let ShuffledList = this.shuffle(answersList);
            ShuffledList.forEach((answer, index) => {
                row.push(answer);
                if (index < answersCount - 1) {
                    row.push("");
                    row.push("");
                }
            })
            schema.push(row);
            schema.push([...emptyRow]);

        }

        this.setState({
            schema: schema
        })
    }

    pipePath = (firstAnswer, secondAnswer) => {
        // first answer coordinates
        let firstAnswerCoordinates = firstAnswer.split('-');
        let firstAnswerX = Number(firstAnswerCoordinates[0]);
        let firstAnswerY = Number(firstAnswerCoordinates[1]);

        // second answer coordinates
        let secondAnswerCoordinates = secondAnswer.split('-');
        let secondAnswerX = Number(secondAnswerCoordinates[0]);

        let pipePath = [];
        let id = firstAnswerX + '-' + (firstAnswerY + 1);
        // pipePath.push({ id, pipe: "ONE" });
        // // 2
        // id = firstAnswerY + 2 + '-' + firstAnswerX;

        // check th distance between columns
        let distance = firstAnswerX - secondAnswerX;

        // check direction
        // goes right
        if (distance < 0) {
            pipePath.push({ id, pipe: "THREE" });
            // 2+
            let nextX = '';
            for (let i = 0; i < (Math.abs(distance) - 1); i++) {
                nextX = firstAnswerX + 1 + i;
                id = String(nextX) + '-' + (firstAnswerY + 1);
                pipePath.push({ id, pipe: "TWO" });
            }
            // last
            id = String(nextX + 1) + '-' + (firstAnswerY + 1);
            pipePath.push({ id, pipe: "FIVE" });

            // goes left
        } else if (distance > 0) {
            pipePath.push({ id, pipe: "SIX" });
            // 2+
            let nextX = '';
            for (let i = 0; i < (distance - 1); i++) {
                nextX = firstAnswerX - 1 - i;
                id = nextX + '-' + (firstAnswerY + 1);
                pipePath.push({ id, pipe: "TWO" });
            }
            // id = firstAnswerX + 2 + '-' + (nextY + 1);
            // pipePath.push({ id, pipe: "THREE" });
            // last
            id = String(nextX - 1) + '-' + (firstAnswerY + 1);
            pipePath.push({ id, pipe: "FOUR" });

        } else {
            pipePath.push({ id, pipe: "ONE" });
            // id = firstAnswerX + 3 + '-' + String(firstAnswerY);
            // pipePath.push({ id, pipe: "TWO" });
        }

        return pipePath
    }

    cleanPipes = () => {
        let updatedSchema = [...this.state.schema];
        const selectedAnswers = [...this.state.selectedAnswers];
        const firstAnswer = selectedAnswers[selectedAnswers.length - 2];
        const secondAnswer = selectedAnswers[selectedAnswers.length - 1];

        let pipeToRemove = this.pipePath(firstAnswer, secondAnswer);

        pipeToRemove.forEach(segment => {
            console.log(segment);
            let coordinateX = this.getCoordinate(segment.id, "x");
            let coordinateY = this.getCoordinate(segment.id, "y");
            updatedSchema[coordinateY][coordinateX] = "";
        })

    }

    finishPipes = (selectedAnswers) => {

        // check if it's the last answer
        let questionsCount = questionsJSON.length;
        let answersCount = selectedAnswers.length;

        if (questionsCount + 1 === answersCount) {
            this.updatePipes('6-16', selectedAnswers);
        }
    }

    updatePipes = async (answerCellId, selectedAnswers) => {

        let updatedSchema = [...this.state.schema];
        let lastAnswerId = selectedAnswers[selectedAnswers.length - 1];

        var pipePath = await this.pipePath(lastAnswerId, answerCellId);

        // console.log(pipePath);

        pipePath.forEach(segment => {

            let coordinateX = this.getCoordinate(segment.id, 'x');
            let coordinateY = this.getCoordinate(segment.id, 'y');
            let direction = segment.pipe;

            updatedSchema[coordinateY][coordinateX] = { pipe: direction }

        })

        this.setState({
            schema: updatedSchema
        })

        return updatedSchema;
    }

    getCoordinate = (str, xy) => {
        let coordinates = str.split('-');
        let coordinate = "";
        if (xy === 'x') {
            coordinate = Number(coordinates[0]);
        } else if (xy === 'y') {
            coordinate = Number(coordinates[1]);
        } else {
            console.log("wrong coordinate")
        }

        return coordinate
    }

    selectAnswer = async (e) => {

        //This would give you all the field of the target
        let selectedId = e.target.id;
        let selectedAnswers = [...this.state.selectedAnswers];

        // method checks if the answer for the same row was already selected
        // if the row is alsready presend in selectedAnswers it returns Id of previously selected answer or false
        const answerCheck = async (answersList, newId) => {
            let newSelectedRowId = this.getCoordinate(newId, "y");
            let result = '';

            // check if the last answer
            const questionsCount = questionsJSON.length;
            const answersCount = selectedAnswers.length;

            const lastAnswer = questionsCount + 1 === answersCount;
            if (lastAnswer) {
                return "block";
            }

            // check if 
            await answersList.forEach(answerId => {
                let answerRowId = this.getCoordinate(answerId, "y");
                if (answerRowId === newSelectedRowId) {
                    // result = "update";
                    result = "block";

                } else if (answerRowId > newSelectedRowId) {
                    result = "block";
                } else if (answerRowId + 2 < newSelectedRowId) {
                    result = "warning";
                } else {
                    result = "add";
                }
            })
            return result
        }

        // check if there is a column in selectedAnswers already
        let answerCheckResult = await answerCheck(selectedAnswers, selectedId);


        if (answerCheckResult === 'update') {
            // remove pipes / update schema
            this.cleanPipes();
            // remove last answer and add a new one
            selectedAnswers.pop();
            // add new pipes
            await this.updatePipes(selectedId, selectedAnswers);

            selectedAnswers.push(selectedId);
            this.finishPipes(selectedAnswers)

            this.setState({
                selectedAnswers: selectedAnswers
            })

        } else if (answerCheckResult === 'block') {
            // show notification "You cannot edit this answer. Do you want to reset?"
            this.toggleAlertResetModal();
        } else if (answerCheckResult === 'warning') {
            // show notification "You have to unswer previous question first"
            this.toggleAlertOKModal();
        } else {
            // build pipes
            await this.updatePipes(selectedId, selectedAnswers);
            selectedAnswers.push(selectedId);
            this.finishPipes(selectedAnswers)
            // add a new answer
            this.setState({
                selectedAnswers: [...this.state.selectedAnswers, selectedId],
            })
            // update Points 
            let selectedAnswerRowId = this.getCoordinate(selectedId, "y");
            let selectedAnswerColumnId = this.getCoordinate(selectedId, "x");

            let answerPoints = this.state.schema[selectedAnswerRowId][selectedAnswerColumnId]['points'];
            this.updatePoints(answerPoints);

            let answerScore = this.state.schema[selectedAnswerRowId][selectedAnswerColumnId]['score'];
            this.updateScore(answerScore)

            // GA Event
            let answerId = Number(this.state.schema[selectedAnswerRowId][selectedAnswerColumnId]['id'][0]) + 1;
            Event("Answer Flow", `User answered question ${answerId}`);

            // move to the next question
            this.updateActiveQuestion();
            // update comment for terminal
            let comment = this.state.schema[selectedAnswerRowId][selectedAnswerColumnId]['hint'];
            let commentTitle = questionsJSON[answerId - 1]['name'];
            this.setState({
                comment: comment,
                commentTitle: commentTitle
            })

            // check if it's the last question
            const questionsCount = questionsJSON.length;
            const answersCount = selectedAnswers.length;
            this.checkResult();
            this.updateDashboard();
            if (questionsCount + 1 === answersCount) {
                this.setState({
                    gameIsOver: true
                })
                this.updateDashboard();
                // this.checkResult();
                // setTimeout(this.checkResult(), 10000);
                setTimeout(() => { this.toggleResultModal() }, 1000);
            }
        }

    }

    updateActiveQuestion = () => {
        let questionsCount = questionsJSON.length;
        if (this.state.activeQuestion < questionsCount - 1) {
            this.setState({
                activeQuestion: this.state.activeQuestion + 1
            })
        }
    }

    resetAnswers = () => {
        // GA Event
        Event("Game Transition", "User clicked the REPLAY button");
        this.populateSchema();
        this.setState({
            selectedAnswers: ['6-0'],
            activeQuestion: 0,
            points: 0,
            score: [],
            resultsMessage: '',
            resultIsOpen: false,
            gameIsOver: false,
            dashboard: DASHBOARD6
        })
    }

    updateDashboard = () => {

        let dashboardList = [DASHBOARD4, DASHBOARD5, DASHBOARD6];

        let dashboardDict = { wrong: DASHBOARD7, bad: DASHBOARD3, okay: DASHBOARD2, best: DASHBOARD1 }
        let newDashboard = '';
        if (this.state.gameIsOver) {
            newDashboard = dashboardDict[this.state.scoreFinal];

        } else {
            // pick a random img
            function getRandomInt(max) {
                return Math.floor(Math.random() * max);
            }
            newDashboard = dashboardList[getRandomInt(3)];

        }
        this.setState({
            dashboard: newDashboard
        })
    }

    addBrokenPipes = () => {

    }


    buildTable = () => {

        return (
            <>

                <table id="pipes-table-m">
                    <thead>
                    </thead>
                    <tbody>

                        {this.state.schema[4] && this.state.schema.map((row, index) => {
                            return <Row
                                activeQuestion={this.state.activeQuestion}
                                key={index}
                                rowIndex={index}
                                row={row}
                                selectAnswer={this.selectAnswer}
                                selectedAnswers={this.state.selectedAnswers} />
                        })}

                    </tbody>
                </table>
            </>
        )
    }


    render() {
        return (
            <>
                <div className="d-flex flex-column ">

                    <div>
                        {this.state.schema[8] && <QuestionPreview
                            schema={this.state.schema}
                            activeQuestion={this.state.activeQuestion} />}
                    </div>

                    {/* <div className="pipes-container-m">
                        <div className="d-flex flex-row">
                            <div className="question-table-m">
                                <QuestionTitlesMobile activeQuestion={this.state.activeQuestion} />
                            </div>
                            <div>
                                <div className="clouds-container-m">
                                    <img src={CLOUDS} alt="" />

                                </div>
                                {this.state.schema[4] ? this.buildTable() : <></>}

                            </div>
                        </div>

                        <img id="pipes-end-right-m" src={PIPES_END_RIGHT} alt="" />

                    </div> */}
                    <div className="pipes-container-m  d-flex flex-column align-self-end">
                        <div className="d-flex flex-row clouds-container-m justify-content-center">
                            <div className="question-table-m"></div>
                            <div className="cloud-img-container">
                                <img src={CLOUDS} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-row m-auto">
                            <div className="question-table-m">
                                <QuestionTitlesMobile activeQuestion={this.state.activeQuestion} />
                            </div>
                            <div>
                                {this.state.schema[4] ? this.buildTable() : <></>}
                            </div>
                        </div>

                        {/* <img id="pipes-end-right-m" src={PIPES_END_RIGHT} alt="" /> */}

                    </div>

                    <div className="first-m">

                        <div className="d-flex flex-row justify-content-center">

                            {/* <div className="pipes-end-left-block-m">
                                <img id="pipes-end-left-m" src={PIPES_END_LEFT} alt="" />
                            </div> */}

                            <div className="dashboards-m d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="score-block-m d-flex align-items-center justify-content-center">
                                        <p>Score: {this.state.points}</p>

                                    </div>
                                </div>

                                <div className="dashboard-container-m">
                                    <img id="" src={this.state.dashboard} alt="" />
                                </div>
                                {/* <div className="terminal-container-m">
                                    <img id="terminal" src={TERMINAL} alt="" />
                                    <div className="terminal-text-m ">
                                        {this.state.activeQuestion !== 0 ?
                                            <div className="">
                                                <p className="terminal-title-m">$ tail -f my_answers.log | grep “{this.state.commentTitle}”</p>
                                                <span>{'[INFO]'} {this.state.comment}</span>
                                            </div>
                                            :
                                            <><p className="terminal-title-m">$ ...</p></>}
                                    </div>

                                </div> */}
                            </div>
                        </div>
                    </div>




                </div>

                <ResultModal
                    resultIsOpen={this.state.resultIsOpen}
                    toggleResultModal={this.toggleResultModal}
                    resultsMessage={this.state.resultsMessage}
                    finishTheGame={this.props.finishTheGame}
                    resetAnswers={this.resetAnswers}
                    points={this.state.points}
                />

                <AlertModalOk
                    alertOKIsOpen={this.state.alertOKIsOpen}
                    toggleAlertOKModal={this.toggleAlertOKModal}
                />

                <AlertModalReset
                    alertResetIsOpen={this.state.alertResetIsOpen}
                    toggleAlertResetModal={this.toggleAlertResetModal}
                    resetAnswers={this.resetAnswers}
                />

            </>
        )
    }
}
