import React from 'react';
import LOGO from '../imgs/logo/21-20035-SPLK-Open-Telemetry-Game-Pipe-Storm-Logo.svg';
import './placeholder.css';

export default function WillStartSoon() {
    return (
        <div>
            <div className="central-block2">
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <div>
                        <div className="d-flex justify-content-center">
                            <img id="logo-center" src={LOGO} alt="logo" />
                        </div>
                        <div className=" d-flex justify-content-center mt-3 m-auto text-center" >
                            <h3>This quest will start soon!</h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
